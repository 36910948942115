export const tokenKey = 'mezzoApp-token';
export const userNameStorage = 'mezzoApp-userName';
export const userFirstAccessKey = 'mezzoApp-userFirstAccess';
export const userFromRecurrentCompanyKey = 'mezzoApp-userFromRecurrentCompany';
export const userIdKey = 'mezzoApp-userId';
export const userSuitabilityKey = 'mezzoApp-userSuitability';
export const correctAnswerKey = 'mezzoAppCorrectAnswerKeyTrue';

export enum LeadStatus {
  pending = 1,
  notified = 2,
  expired = 3,
  dealClosed = 4,
}

export enum CustomerStatus {
  active = 1,
  inactive = 2,
}

export enum TraitClassificationEnum {
  exact = 1,
  interval = 2,
}

export enum SalesAgentsStatusEnum {
  active = 1,
  inactive = 2,
}

export enum ProductStatusEnum {
  available = 1,
  sold = 2,
  unavailable = 3,
}

export enum RecurringProductStatusEnum {
  active = 1,
  inactive = 2,
}

export const salesAgentsStatusOptions = [
  { value: SalesAgentsStatusEnum.active, label: 'Ativo' },
  { value: SalesAgentsStatusEnum.inactive, label: 'Inativo' },
];

export const traitClassificationOptions = [
  { value: TraitClassificationEnum.exact, label: 'Exato' },
  { value: TraitClassificationEnum.interval, label: 'Intervalo' },
];

export const productStatusOptions = [
  { value: ProductStatusEnum.available, label: 'Em estoque' },
  { value: ProductStatusEnum.sold, label: 'Negócio fechado' },
  { value: ProductStatusEnum.unavailable, label: 'Baixado' },
];

export const recurringProductStatusOptions = [
  { value: RecurringProductStatusEnum.active, label: 'Ativo' },
  { value: RecurringProductStatusEnum.inactive, label: 'Inativo' },
];

export const leadStatusOptions = [
  { value: LeadStatus.pending, label: 'Pendente' },
  { value: LeadStatus.notified, label: 'Notificado' },
  { value: LeadStatus.dealClosed, label: 'Negócio fechado' },
  { value: LeadStatus.expired, label: 'Baixado' },
];

export const customerStatusOptions = [
  { value: CustomerStatus.active, label: 'Ativo' },
  { value: CustomerStatus.inactive, label: 'Inativo' },
];

export const educationOptions = [
  { value: 'Escolaridade*', label: 'Escolaridade*' },
  { value: 'graduated', label: 'Superior completo' },
  { value: 'nonGraduated', label: 'Superior incompleto' },
];

export const months = [
  { value: '0', label: 'Janeiro' },
  { value: '1', label: 'Fevereiro' },
  { value: '2', label: 'Março' },
  { value: '3', label: 'Abril' },
  { value: '4', label: 'Maio' },
  { value: '5', label: 'Junho' },
  { value: '6', label: 'Julho' },
  { value: '7', label: 'Agosto' },
  { value: '8', label: 'Setembro' },
  { value: '9', label: 'Outubro' },
  { value: '10', label: 'Novembro' },
  { value: '11', label: 'Dezembro' }
];

export const years = [
  { value: '2', label: '2024' },
  { value: '1', label: '2023' },
  { value: '0', label: '2022' },
];

export const ufs = [
  { value: 'Estado*', label: 'Estado*' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export const localStorageParameters = {
  userName: 'userName',
  company: 'company',
  optionSelected: 'optionSelected',
  containerSelected: 'containerSelected',
  securityToken: 'securityToken',
};

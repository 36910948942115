import React, { useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import {
  DashboardCard,
  DashboardCardContent,
  DashboardCardContentComplement,
  DashboardCardTitle,
  SectionContainer,
  SectionTitle,
  TableContainer,
} from './styles';
import rocketImage from '../../../images/dashboardCardsImages/rocket.png';
import trending_image from '../../../images/dashboardCardsImages/trending_up.png';
import check_circle_outline from '../../../images/dashboardCardsImages/check_circle_outline.png';
import query_builder from '../../../images/dashboardCardsImages/query_builder.png';
import CustomTable from '../../../shared/components/table';
import Chart from 'react-apexcharts';
import CustomSelect from '../../../shared/components/select';
import { months, years } from '../../../shared/util/constants';
import { defaultColors } from '../../../styles/theme';

const tableColumns = ['Descrição', 'Saldo em 2023', 'Novas dívidas', 'Pagamentos', 'Saldo atual'];

const dataRows = [
  {
    description: 'Bancos',
    lastYear: '1.589.108',
    new: '19.838',
    payments: '151.666',
    currentYear: '1.457.280',
  },
  {
    description: 'Matéria prima',
    lastYear: '763.385',
    new: '675.273',
    payments: '298.328',
    currentYear: '1.140.331',
  },
  {
    description: 'Tributos',
    lastYear: '693.475',
    new: '123.564',
    payments: '180.111',
    currentYear: '636.928',
  },
];

const DashboardFinances = () => {
  let chartOptionsDefault = {
    options: {
      xaxis: {
        categories: ['01/2024', '02/2024', '03/2024', '04/2024', '05/2024', '06/2024', '07/2024', '08/2024'],
      },
      chart: {
        height: 50,
        toolbar: {
          show: false,
        },
      },
      colors: [defaultColors.purple.main],
    },
    series: [
      {
        name: 'Resultado operacional',
        data: [62153, 9870, 76615, 46013, 86951, 33985, 38306, 61735],
      },
    ],
  };

  let chartBalanceDefault = {
    options: {
      xaxis: {
        categories: ['01/2024', '02/2024', '03/2024', '04/2024', '05/2024', '06/2024', '07/2024', '08/2024'],
      },
      chart: {
        height: 50,
        toolbar: {
          show: false,
        },
      },
      colors: [defaultColors.purple.main],
    },
    series: [
      {
        name: 'Saldo bancário',
        data: [20470, 15701, 15843, 10493, 2937, 15906, 13610, 9251],
      },
    ],
  };

  let pieOptions = {
    series: [158555, 46350, 60000],
    options: {
      chart: {
        height: 50,
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
      },
      labels: ['Vendas internas', 'Exportações', 'Antecipações'],
    },
  };

  const [chartOptions, setChartOptions] = useState<any>(chartOptionsDefault);
  const [chartBalance, setChartBalance] = useState<any>(chartBalanceDefault);
  const [chartPieOptions, setChartPieOptions] = useState<any>(pieOptions);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  return (
    <Box height={'100%'} mt={'32px'}>
      <PageStructure title='Agosto-2024' hideRightColumn={true}>
        <Box display={'flex'} gap={'8px'} flexDirection={'column'} width={'100%'}>
        <Box display={'flex'} flexDirection={'column'} gap={'20px'} width={'100%'}>
              {/* Cards */}
              <Box gap={'20px'} width={'100%'} display={'flex'} flexDirection={'row'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'100%'}
                  gap={'16px'}
                  justifyContent={'space-between'}
                >
                  <Box display={'flex'} gap={'20px'} width={'100%'}>
                    <DashboardCard urlImage={check_circle_outline}>
                      <DashboardCardTitle>Saldo Bancário</DashboardCardTitle>
                      <DashboardCardContent>{'$ 9.251'}</DashboardCardContent>
                    </DashboardCard>
                    <DashboardCard urlImage={check_circle_outline}>
                      <DashboardCardTitle>Resultado operacional</DashboardCardTitle>
                      <DashboardCardContent>{'$ 61.735'}</DashboardCardContent>
                    </DashboardCard>
                    <DashboardCard urlImage={query_builder}>
                      <DashboardCardTitle>Previsão de entrada mês seguinte</DashboardCardTitle>
                      <DashboardCardContent>{'$ 141.176'}</DashboardCardContent>
                    </DashboardCard>
                    <DashboardCard urlImage={query_builder}>
                      <DashboardCardTitle>Previsão de saída mês seguinte</DashboardCardTitle>
                      <DashboardCardContent>{'$ 177.165'}</DashboardCardContent>
                    </DashboardCard>
                  </Box>
                </Box>
              </Box>
              <Box mt={'12px'} display={'flex'} gap={'36px'} width={'100%'}>
                {/*resultado */}
                <Box width={'50%'} display={'flex'} flexDirection={'column'}>
                  <Box flexDirection={'column'} style={{ flex: 1 }}>
                    <SectionTitle>Histórico de resultado operacional</SectionTitle>
                    <Chart options={chartOptions.options} series={chartOptions.series} type="line" width={'100%'} />
                  </Box>
                </Box>

                {/*saldo */}
                <Box width={'50%'} display={'flex'} flexDirection={'column'}>
                  <Box flexDirection={'column'} style={{ flex: 1 }}>
                    <SectionTitle>Histórico de saldo bancário</SectionTitle>
                    <Chart options={chartBalance.options} series={chartBalance.series} type="line" width={'100%'} />
                  </Box>
                </Box>

                {/*Produtos by status */}
                <Box width={'50%'} display={'flex'} flexDirection={'column'}>
                  <Box flexDirection={'column'} style={{ flex: 1 }}>
                    <SectionTitle>Entradas conforme tipo da venda</SectionTitle>
                    <Chart
                      options={chartPieOptions.options}
                      series={chartPieOptions.series}
                      type="donut"
                      width={'100%'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          
          {/* Lead x interests table */}
          <Box mt={'12px'} width={'100%'} display={'flex'} gap={'20px'}>
            <Box width={'60%'}>
              <TableContainer>
                <SectionTitle>Evolução da dívida</SectionTitle>
                <Box mt={'4px'}>
                  <CustomTable dataColumns={tableColumns} dataRows={dataRows} />
                </Box>
              </TableContainer>
            </Box>

            <Box display={'flex'} gap={'20px'} width={'40%'} flexDirection={'column'}>
              <DashboardCard urlImage={rocketImage}>
                <DashboardCardTitle>Resultado operacional acumulado</DashboardCardTitle>
                <Box display={'flex'} gap={'4px'} alignItems={'flex-end'}>
                  <DashboardCardContent>{'$ 415.633'}</DashboardCardContent>
                </Box>
              </DashboardCard>
              <DashboardCard urlImage={check_circle_outline}>
                <DashboardCardTitle>Total de juros pagos no período</DashboardCardTitle>
                <Box display={'flex'} gap={'4px'} alignItems={'flex-end'}>
                  <DashboardCardContent>{'$ 118.087'}</DashboardCardContent>
                </Box>
              </DashboardCard>
            </Box>
          </Box>
        </Box>
      </PageStructure>
    </Box>
  );
};

export default DashboardFinances;

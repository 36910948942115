import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={55}
    height={27}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M18.746.46c-5.89 1.596-11.382 6.934-14.85 14.451C2.854 17.212 1.228 22 .307 25.558L0 26.693H16.077l.368-1.749c.583-2.761 2.946-7.916 5.216-11.29 3.314-5.002 8.56-9.788 13.99-12.795L37.218 0l-8.438.03c-6.259 0-8.867.123-10.033.43ZM37.892 2.087c-1.626 6.259-6.351 13.837-11.475 18.378-2.24 1.994-6.505 4.97-8.039 5.615-.521.214-.95.49-.95.644 0 .153 3.62.276 8.069.276 8.959 0 10.063-.153 13.653-1.964 2.454-1.227 6.167-4.602 8.13-7.333 2.7-3.773 5.43-10.216 6.812-15.954L54.49 0H38.413l-.521 2.087Z"
    />
  </svg>
);

export default Logo;

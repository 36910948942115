import { createTheme } from '@mui/material/styles';

export const defaultShadow = '2px 2px 8px rgba(132, 132, 132, 0.05)';

export const mobileBreakPoint = 768;

export const defaultColors = {
  textColor: '#444444',
  backgroundColor: '#F9FAFE',
  white: {
    main: '#FFFFFF',
    light: '#F9F9F9',
  },
  red: {
    main: '#F76257',
    light: '#FDDCDC',
    dark: '#C44D45',
  },
  gray: {
    main: '#777777',
    light: '#E0E0E0',
  },
  green: {
    main: '#3EF7AE',
    light: '#D5FBEC',
    dark: '#31C489',
  },
  yellow: {
    main: '#F7E04A',
    light: '#FBF7E2',
  },
  purple: {
    main: '#262FC9',
    light: '#8B95E1',
    dark: '#1A2299',
  },
};

interface IColorGrading {
  main: string;
  light?: string;
  dark?: string;
}

interface ICustomizedPallete {
  white: IColorGrading;
  textColor: string;
  red: IColorGrading;
  gray: IColorGrading;
  green: IColorGrading;
  yellow: IColorGrading;
  purple: IColorGrading;
  backgroundColor: string;
}

declare module '@mui/material/styles' {
  interface Palette extends ICustomizedPallete {}
  interface PaletteOptions extends ICustomizedPallete {}
}

const theme = createTheme({
  palette: {
    white: defaultColors.white,
    textColor: defaultColors.textColor,
    red: defaultColors.red,
    gray: defaultColors.gray,
    green: defaultColors.green,
    yellow: defaultColors.yellow,
    backgroundColor: defaultColors.backgroundColor,
    purple: defaultColors.purple,
  },
  typography: {
    fontFamily: 'Sora',
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
});

export default theme;

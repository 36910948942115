import { TableContainer, TableCell, tableCellClasses, TableRow, Typography, tableRowClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)((props) => ({}));

export const StyledTableCell = styled(TableCell)((props) => ({
  [`&.${tableCellClasses.head}`]: {
    color: props.theme.palette.gray.main,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
    border: 'none',
    padding: '8px 0px 0px 0px',
    textAlign: 'center',

    '&:first-of-type': {
      textAlign: 'start',
      width: '20%',
    },

    '&:last-of-type': {
    },

    '@media (max-width: 768px)': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: props.theme.palette.textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    padding: '8px 0px',
    textAlign: 'center',

    '&:first-of-type': {
      textAlign: 'start',
    },

    '&:last-of-type': {
    },

    '@media (max-width: 768px)': {
      fontSize: '12px',
      lineHeight: '12px',
      maxWidth: '150px',
    },
  },
}));

export const StyledTableRow = styled(TableRow)((props) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledLabelAction = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '12px',
  color: props.theme.palette.purple.main,
  textDecorationLine: 'underline',
  cursor: 'pointer',
}));

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface IContainerProps {
  expandAsideMenu: boolean;
}

export const Container = styled(Box)<IContainerProps>((props) => ({
  display: 'grid',
  gridTemplateColumns: '250px auto',
  gridTemplateRows: '0px auto',
  gridTemplateAreas: `'AS HD'
    'AS CT'`,
  height: '100vh',
  backgroundColor: props.theme.palette.backgroundColor,

  '@media (max-width: 768px)': {
    gridTemplateColumns: props.expandAsideMenu ? 'auto 0' : '0 auto',
    overflow: 'hidden',
    gridTemplateAreas: `'HD HD'
    'AS CT'`,
    padding: '0px',
  },

  '@media (max-height: 520px)': {
    overflow: 'scroll',
  },
}));

import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { tokenKey } from '../../../shared/util/constants';
import LoginForm from '../components/loginForm';
import CustomAlert from '../../../shared/components/alert';
import CustomSnackBar from '../../../shared/components/toast';
import { ToastContext } from '../../../contexts/toast';
import { Container, FormContainer, Wave, WavyBackground } from './styles';

const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem(tokenKey));
  const { message, type } = useContext(ToastContext);
  const [snackOpen, setSnackOpen] = useState(!!message);

  useEffect(() => {
    setSnackOpen(!!message);
  }, [message]);

  return (
    <>
      {isAuthenticated ? (
        <Navigate to={'/finances'} />
      ) : (
        <WavyBackground>
          <Container>
            <FormContainer>
              <LoginForm setIsAuthenticated={() => setIsAuthenticated(true)} />
            </FormContainer>
          </Container>
          <Wave xmlns="http://www.w3.org/2000/svg" viewBox="60 0 600 150" preserveAspectRatio="none">
            <path d="M0,100 C150,50 350,150 500,100 C650,50 850,150 1000,100 C1150,50 1350,150 1500,100 L1500,200 L0,200 Z" />
          </Wave>
        </WavyBackground>
      )}
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={type}
          title={message}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </>
  );
};

export default Login;

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  width: '450px',
  backgroundColor: theme.palette.white.main,
  boxShadow: '2px 2px 20px rgba(132, 132, 132, 0.2)',
  borderRadius: '28px',
  padding: '36px 56px',
  marginTop: '-10vh',
  zIndex: 3,
}));

export const WavyBackground = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: '100vh',
  background: theme.palette.backgroundColor,
}));

export const Wave = styled('svg')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  fill: theme.palette.purple.main,
}));


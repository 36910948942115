import React, { useContext, useEffect } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { tokenKey } from '../shared/util/constants';
import Layout from '../shared/layout/layout';
import { getUserNotificationsService } from '../services/communication';
import { NotificationContext } from '../contexts/notification';

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem(tokenKey);
  const { setUserNotifications } = useContext(NotificationContext);

  const getUserNotifications = async () => {
    const response = await getUserNotificationsService();

    if (response.status === 200) {
      const notificationsResult = response.data.result;

      setUserNotifications(notificationsResult);
    }
  };

  useEffect(() => {
    Promise.all([getUserNotifications()]);
  }, [children]);

  return !isAuthenticated ? (
    <Navigate to={'/login'} />
  ) : (
    <Layout>
      <>{children}</>
    </Layout>
  );
};

export default PrivateRoute;

import React, { useState } from 'react';

import Header from '../header';
import Aside from '../aside';
import Content from '../content';

import { Container } from './styles';
import { mobileBreakPoint } from '../../../styles/theme';

const Layout: React.FC = ({ children }) => {
  const [expandAsideMenu, setExpandAsideMenu] = useState(window.screen.width > mobileBreakPoint);

  return (
    <>
      <Container expandAsideMenu={expandAsideMenu}>
        <Content expandAsideMenu={expandAsideMenu}>{children}</Content>
        {/* <Header setExpandAsideMenu={() => setExpandAsideMenu(!expandAsideMenu)} expandAsideMenu={expandAsideMenu} /> */}
        <Aside setExpandAsideMenu={() => setExpandAsideMenu(!expandAsideMenu)} expandAsideMenu={expandAsideMenu} />
      </Container>
    </>
  );
};

export default Layout;

import React, { useContext, useEffect, useState } from 'react';

import { ButtonsContainer, StyledLink, InputsContainer, StyledContainer, StyledTitle } from './styles';
import CustomTextInput from '../../../../shared/components/textInput';
import CustomButton from '../../../../shared/components/button';
import { defaultColors } from '../../../../styles/theme';
import { Box } from '@mui/material';
import HelpIcon from '../../../../images/icons/help';
import {
  tokenKey,
  userFirstAccessKey,
  userFromRecurrentCompanyKey,
  userIdKey,
  userNameStorage,
  userSuitabilityKey,
} from '../../../../shared/util/constants';
import { ILoginInput } from '../../../../interfaces/input/login';
import { postLoginService } from '../../../../services/login';
import { AuthenticationContext } from '../../../../contexts/authentication';
import { ToastContext } from '../../../../contexts/toast';

interface ILoginForm {
  setIsAuthenticated: () => void;
}

const LoginForm: React.FC<ILoginForm> = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(AuthenticationContext);
  const { setMessage } = useContext(ToastContext);
  const [disableButton, setDisableButton] = useState(false);

  async function login() {
    setIsLoading(true);
    const input: ILoginInput = {
      email: email,
      password: password,
    };
    const login = await postLoginService(input);

    if (login.status === 200) {
      localStorage.setItem(tokenKey, login.data.result.token);
      localStorage.setItem(userNameStorage, login.data.result.userData.name);
      localStorage.setItem(userFirstAccessKey, login.data.result.userData.firstAccess);
      localStorage.setItem(userFromRecurrentCompanyKey, '1');
      localStorage.setItem(userIdKey, login.data.result.userData.id);
      localStorage.setItem(userSuitabilityKey, login.data.result.userData.suitability);

      setUser({ ...login.data.result.userData, userFromRecurrentCompany: '1' });
      setIsAuthenticated();
    } else {
      setMessage(undefined);
      if (login.response?.data?.result) {
        setMessage({
          message: login.response.data.result,
          type: 'error',
        });
      } else {
        setMessage({
          message: 'Não foi possível realizar o login.',
          type: 'error',
        });
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (!email || !password) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [email, password]);

  return (
    <form style={{ height: '100%' }}>
      <StyledContainer>
        <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <StyledTitle>Vamos começar!</StyledTitle>
          <InputsContainer>
            <CustomTextInput
              minWidth={'100%'}
              value={email}
              onChange={(event: any) => setEmail(event.target.value)}
              placeholder="E-mail"
              maxLength={100}
            />
            <CustomTextInput
              minWidth={'100%'}
              value={password}
              onChange={(event: any) => setPassword(event.target.value)}
              placeholder="Senha"
              maxLength={100}
              type="password"
            />
          </InputsContainer>
        </Box>

        <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
          <Box width={'100%'} justifyContent={'center'} display={'flex'}>
            <ButtonsContainer>
              <CustomButton
                classification="primary"
                uppercase={false}
                title="Entrar"
                typeSubmit
                onClick={login}
                isLoading={isLoading}
                disabled={disableButton}
              />
            </ButtonsContainer>
          </Box>

          <Box display={'flex'} flexDirection={'column'} marginTop={'20px'}>
            {/* <StyledLink href={'https://api.whatsapp.com/send?phone=+5535992575433&text=Olá!'} target="_blank">
              <Box
                display={'flex'}
                sx={{ cursor: 'pointer', fontSize: '14px' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <HelpIcon fill={defaultColors.purple.main} style={{ marginRight: '4px' }} />
                ajuda
              </Box>
            </StyledLink> */}
          </Box>
        </Box>
      </StyledContainer>
    </form>
  );
};

export default LoginForm;

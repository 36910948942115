import * as React from 'react';

import { TableBody, TableHead, TableRow, Table, Box } from '@mui/material';

import { StyledTableContainer, StyledTableCell, StyledTableRow, StyledLabelAction } from './styles';
import PrizeIndicatorIcon from '../../../images/icons/aside/prizeIndicator';

interface ICustomTableProps {
  dataColumns: string[];
  dataRows: any[];
  hasActionColumn?: boolean;
  actionLabel?: string;
  handleActionClick?: any;
  handleRowClick?: any;
}

const CustomTable: React.FC<ICustomTableProps> = ({
  dataColumns,
  dataRows,
  hasActionColumn,
  actionLabel,
  handleActionClick,
  handleRowClick,
}) => {
  const values = Object.getOwnPropertyNames(dataRows[0]);
  return (
    <StyledTableContainer>
      <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', userSelect: 'none' }}>
        <TableHead>
          <TableRow>
            {dataColumns.map((column) => (
              <StyledTableCell key={dataColumns.indexOf(column)}>{column}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataRows.map((row) => (
            <StyledTableRow id="tableRow" key={dataRows.indexOf(row)} onClick={() => {}}>
              {values.map((value) => {
                return (
                  value !== 'id' &&
                  value !== 'userId' && (
                    <StyledTableCell key={value}>
                      {row[value]}
                    </StyledTableCell>
                  )
                );
              })}
              {/* {hasActionColumn && (
                <StyledTableCell>
                  <StyledLabelAction onClick={() => handleActionClick(row)}>{actionLabel}</StyledLabelAction>
                </StyledTableCell>
              )} */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CustomTable;

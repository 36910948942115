import * as React from 'react';

import { FormControl, InputAdornment } from '@mui/material';

import { StyledTextInput, StyledErrorText, StyledLabel } from './styles';
import { defaultColors } from '../../../styles/theme';

interface ICustomTextInputProps {
  label?: string;
  endAdornment?: any;
  startAdornment?: any;
  helperText?: string;
  optional?: string;
  errorMessage?: string;
  value: string | undefined;
  onChange: (event: any) => void;
  fullWidth?: boolean;
  minWidth?: number | string;
  maxLength: number;
  multiline?: boolean;
  rows?: number;
  type?: string;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  onKeyDown?: any;
  size?: 'small' | 'medium';
  id?: string;
}

const CustomTextInput: React.FC<ICustomTextInputProps> = ({
  onBlur,
  type,
  label,
  endAdornment,
  startAdornment,
  errorMessage,
  value,
  onChange,
  fullWidth,
  minWidth,
  maxLength,
  multiline,
  rows,
  placeholder,
  disabled,
  onKeyDown,
  size,
  id,
}) => {
  const [borderColor, setBorderColor] = React.useState(errorMessage ? defaultColors.red.main : defaultColors.gray.main);

  React.useEffect(() => {
    setBorderColor(errorMessage ? defaultColors.red.main : defaultColors.gray.main);
  }, [errorMessage]);

  return (
    <FormControl sx={{ minWidth: fullWidth ? '100%' : minWidth }} variant="outlined">
      {value && (
        <StyledLabel textColor={borderColor} className="label" htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      <StyledTextInput
        size={size ? size : 'medium'}
        onKeyDown={onKeyDown}
        error={!!errorMessage}
        type={type}
        inputProps={{ maxLength: maxLength }}
        multiline={multiline}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
        startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
        onBlur={onBlur}
        disabled={disabled}
        label={value && label}
        id={id}
      />
      {errorMessage && <StyledErrorText>{errorMessage}</StyledErrorText>}
    </FormControl>
  );
};

export default CustomTextInput;

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface IContainer {
  expandAsideMenu: boolean;
}

export const Container = styled(Box)<IContainer>((props) => ({
  gridArea: 'CT',
  backgroundColor: props.theme.palette.backgroundColor,
  display: props.expandAsideMenu ? 'auto' : 'block',
  overflowY: 'scroll',
  overflowX: 'hidden',

  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '17px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    margin: '30px',
  },

  '&::-webkit-scrollbar-thumb': {
    padding: ' 0 4px',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    backgroundColor: props.theme.palette.purple.light,
    backgroundClip: 'padding-box',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: props.theme.palette.purple.main,
    },
  },

  '@media (max-width: 768px)': {
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

export const ContentContainer = styled(Box)((props) => ({
  paddingLeft: '2.5%',
  paddingRight: '2.0%',
  height: '100%',

  '@media (max-width: 768px)': {
    paddingTop: '0px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

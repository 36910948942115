import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface IDashboardCard {
  urlImage: string;
}

interface ISectionContainer {
  flexDirection: 'row' | 'column';
}

export const Container = styled(Box)((props) => ({}));

export const DashboardCard = styled(Box)<IDashboardCard>((props) => ({
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  padding: '18px 24px',
  borderRadius: '16px',
  backgroundImage: `url(${props.urlImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  width: '100%',
  backgroundColor: props.theme.palette.purple.main,

  '@media (max-width: 768px)': {
    width: '100%',
    padding: '12px 20px',
  },
}));
  
export const DashboardCardTitle = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '12px',
  fontWeight: '500',

  '@media (max-width: 768px)': {
    fontSize: '10px',
  },
}));
  
export const DashboardCardContent = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '28px',
  fontWeight: '600',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));

export const DashboardCardContentComplement = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '16px',
  fontWeight: '600',
  display: 'flex',
  marginBottom: '6px',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));

export const SectionContainer = styled(Box)<ISectionContainer>((props) => ({
  display: 'flex',
  flexDirection: props.flexDirection,
  width: '100%',
  height: '100%',
  gap: '8px',
  padding: '22px 24px',
  borderRadius: '16px',
  backgroundColor: props.theme.palette.white.main,
  boxShadow: defaultShadow,

  '@media (max-width: 768px)': {
    width: '100%',
    padding: '12px 20px',
  },
}));

export const SectionTitle = styled(Typography)((props) => ({
  color: props.theme.palette.gray.main,
  fontSize: '12px',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));

export const TableContainer = styled(Box)((props) => ({
  maxHeight: '35vh',
  overflowY: 'scroll',
  paddingRight: '24px',

  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '17px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    margin: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    padding: ' 0 4px',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    backgroundColor: props.theme.palette.purple.light,
    backgroundClip: 'padding-box',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: props.theme.palette.purple.main,
    },
  },
}));

import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultColors } from '../../../styles/theme';

export interface StyledButtonProps {
  classification: 'primary' | 'secondary' | 'warning';
  uppercase: boolean;
}

const getButtonBackground = (classification: string) => {
  switch (classification) {
    case 'warning':
      return defaultColors.red.main;
    case 'secondary':
      return 'none';
    default:
      return defaultColors.purple.main;
  }
};

export const StyledButton = styled(Button)<StyledButtonProps>((props) => ({
  backgroundColor: getButtonBackground(props.classification),
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  height: '48px',
  lineHeight: '16px',
  minWidth: '180px',
  color:
    props.classification === 'primary' || props.classification === 'warning'
      ? props.theme.palette.white.main
      : props.theme.palette.purple.main,
  textTransform: props.uppercase ? 'uppercase' : 'none',
  borderRadius: '12px',
  padding: '16px 20px',
  border:
    props.classification !== 'warning'
      ? `1px solid ${props.theme.palette.purple.main}`
      : `1px solid ${props.theme.palette.red.main}`,
  '&:hover': {
    backgroundColor:
      props.classification === 'primary'
        ? props.theme.palette.purple.dark
        : props.classification === 'warning'
        ? props.theme.palette.white.main
        : props.classification === 'secondary'
        ? props.theme.palette.purple.main
        : 'none',
    color:
      props.classification === 'warning'
        ? props.theme.palette.red.main
        : props.classification === 'secondary' && props.theme.palette.white.main,
  },
  '&:focus': {
    border:
      props.classification !== 'warning'
        ? `1px solid ${props.theme.palette.purple.dark}`
        : `1px solid ${props.theme.palette.red.main}`,
  },
  '&:disabled': {
    opacity: '40%',
    color:
      props.classification === 'primary' || props.classification === 'warning'
        ? props.theme.palette.white.main
        : props.theme.palette.purple.main,
  },
}));

export const StyledIconButton = styled(IconButton)<StyledButtonProps>((props) => ({
  backgroundColor: getButtonBackground(props.classification),
  borderRadius: '28px',
  padding: '18px 34px',
  height: '100%',
  border:
    props.classification !== 'warning'
      ? `1px solid ${props.theme.palette.purple.main}`
      : `1px solid ${props.theme.palette.red.main}`,
  '&:hover': {
    backgroundColor:
      props.classification === 'primary'
        ? props.theme.palette.purple.dark
        : props.classification === 'warning'
        ? props.theme.palette.white.main
        : 'none',
    border: props.classification === 'secondary' && `1px solid ${props.theme.palette.purple.dark}`,
    color: props.classification === 'warning' && props.theme.palette.red.main,
  },
  '&:focus': {
    border:
      props.classification !== 'warning'
        ? `1px solid ${props.theme.palette.purple.dark}`
        : `1px solid ${props.theme.palette.red.main}`,
  },
  '&:disabled': {
    opacity: '40%',
    backgroundColor: getButtonBackground(props.classification),
    color:
      props.classification === 'primary' || props.classification === 'warning'
        ? props.theme.palette.white.main
        : props.theme.palette.purple.main,
  },
}));

import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { defaultShadow } from '../../../styles/theme';

interface IContainer {
  expandAsideMenu: boolean;
}

interface IButtonSubItem {
  selected?: boolean;
}

interface ILabelItem {
  selected?: boolean;
}

export const LogoContainer = styled(Box)<IButtonSubItem>((props) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '32px',
  paddingTop: '28px',
}));

export const Container = styled(Box)<IContainer>((props) => ({
  gridArea: 'AS',
  background: props.theme.palette.purple.dark,
  boxShadow: '5px 5px 8px rgba(132, 132, 132, 0.3)',
  
  display: props.expandAsideMenu ? 'block' : 'none',

  '@media (max-width: 768px)': {
    paddingTop: '1vh',
  },
}));

export const MenuItemLink = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  width: '100%',
  height: '100%',
}));

export const ButtonSubItem = styled(Box)<IButtonSubItem>((props) => ({
  width: '100%',
  height: '100%',
  paddingTop: '14px',
  paddingBottom: '14px',
  paddingLeft: '30px',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  color: props.theme.palette.white.main,
  backgroundColor: props.selected ? props.theme.palette.purple.main : 'transparent',
}));

export const LabelItem = styled(Typography)<ILabelItem>((props) => ({
  display: 'flex',
  fontSize: '14px',
  justifyContent: 'center',
  marginTop: '6px',
  fontWeight: props.theme.typography.fontWeightMedium,
  letterSpacing: '0.5px',

  '@media (max-width: 768px)': {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '33px',
  },
}));

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',

  '@media (max-width: 768px)': {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingBottom: '30px',
  },
}));

export const StyledTitle = styled(Typography)((props) => ({
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  letterSpacing: '0.3px',
  color: props.theme.palette.gray.dark,

  '@media (max-width: 768px)': {
    marginTop: '25%',
    fontSize: '16px',
  },
}));

export const InputsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '32px',
  marginBottom: '16px',
}));

export const StyledLink = styled('a')((props) => ({
  cursor: 'pointer',
  color: props.theme.palette.purple.main,
}));

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '12px',
  justifyContent: 'center',
  width: '100%',
}));
